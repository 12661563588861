<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { encryptData } from "@/helpers/crypt.js";
import { containsDisallowedCharacters } from "../../../../../utils/check-disallowed-character";
/**
 * Dashboard Component
 */

export default {
  page: {
    title: "Tambah Jadwal Rekrutmen",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  props: {
    dataPeserta: {
      type: String,
    },
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      data_peserta: [],
      data_jadwal: [],

      form_data: {
        j_metode: "Rekrutmen",
        m_unit_idm_unit: "",
        j_txt_unit: "",
        m_lokasi_idm_lokasi: "",
        j_txt_lokasi: "", //
        j_metode_jadwal: "",
        m_user_id_ptl: "",
        m_user_nip_ptl: "",
        j_txt_ptl: "",
        j_json_petugas: "",
        j_tanggal_mulai: "", //
        j_tanggal_sampai: "", //
        j_jam_mulai: "", //
        j_jam_sampai: "", //
        j_mulai_penilaian: "",
        j_akhir_penilaian: "",
        j_impact: "",
        j_keterangan: "",
        j_agreement: "",
        m_jadwal_kegiatan_idm_kegiatan: "13", //
        j_txt_kegiatan: "Rekrutmen",
        m_dirkom_idm_dirkom: "",
        j_txt_dirkom: "",
        j_tipe: "Potensi",
        m_batre_test_bt_id: "",
        j_txt_batre_test: "",
        j_materi: "",
        user_id: localStorage.getItem("session_user_id"),
        user_name: localStorage.getItem("session_name"),
        j_bentuk_dinamika: "",
        j_bentuk_penilaian: "",
        j_json_alattes: "",
        j_json_unitclient: "",
        j_json_checklist: "",
        j_nama_grup: "",
        row_id: "",
        id_alattes_global: "",
        m_id_subkegiatan: "",
        m_nama_kegiatan: "",
      },

      option_unit: [],
      selected_unit: "",

      option_lokasi: [],
      checkedLokasi: false,
      selected_lokasi: "",

      temp_id_jadwal: "",
      selected_metode: "",

      option_kegiatan: [],
      selected_kegiatan: "",

      option_sub_kegiatan: [],
      selected_sub_kegiatan: "",

      option_jenis_asesmen: [],
      seleceted_jenis_asesmen: "",

      option_batre_test: [],
      seleceted_batre_test: "",

      option_alat_test: [],

      json_fix_peserta: [],

      form_unit: [
        {
          m_unit_slug: "",
          m_unit_idm_unit: "",
          jadwal_idjadwal: null,
          ju_txt_unit: "",
          jumlah_diundang: "",
        },
      ],
      selected_unit_peserta: [],
      selected_jumlah_peserta: [],

      lokasi_text: "",
      json_petugas: "",

      option_asesor: [],
      option_tugas: [],
      form_tugas: [
        {
          tugas_id: "",
          tugas_txt: "",
          jadwal_idjadwal: null,
          petugas_id: "",
          petugas_txt: "",
        },
      ],

      selected_tugas: [],
      selected_petugas_aesor: [],

      option_checklist: [],
      form_checklist: [
        {
          id_ca: "",
          id_checklist: "",
          nama_checklist: "",
          jadwal_idjadwal: null,
        },
      ],
      selected_checklist: [],

      showbatre: false,

      cart: [],
      grup_name: "",

      option_ptl: [],
      selected_ptl: [],
    };
  },

  mounted() {
    let self = this;
    self.searchUnit(true);
    self.searchLokasi(true);
    // self.searchKegiatan(true);
    self.searchSubKegiatan(true);
    self.searchJenisAsesmen(true);
    self.searchBatreTest(true);
    self.searchTugas(true);
    self.searchAsesor(true);
    self.searchPTL(true);

    if (sessionStorage.cart) {
      const cart1 = sessionStorage.cart;
      self.cart = JSON.parse(cart1);
      // console.log(self.cart);
    }
  },

  created() {
    // this.data_peserta = JSON.parse(window.history.state.data_peserta);
    // this.data_jadwal = JSON.parse(window.history.state.data_jadwal);
    // this.form_data.m_user_id_ptl = this.data_jadwal.m_user_id_ptl;
    // this.form_data.m_user_nip_ptl = this.data_jadwal.m_user_nip_ptl;
    // this.form_data.j_txt_ptl = this.data_jadwal.j_txt_ptl;
    // this.form_data.j_json_petugas = this.data_jadwal.j_json_petugas;
    // this.json_petugas = JSON.parse(this.data_jadwal.j_json_petugas);
    // console.log(this.data_peserta);
  },
  methods: {
    encryptedUrl(params) {
      const encryptedText = encryptData(params);
      return encryptedText;
    },
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },

    addCart() {
      let self = this;
      var randomkey = this.randomKey();
      self.form_data.row_id = randomkey;
      self.cart.push({
        row_id: randomkey,
        param: JSON.parse(JSON.stringify(self.form_data)),
      });
      console.log(self.cart);

      sessionStorage.setItem("cart", JSON.stringify(self.cart));
      // console.log(sessionStorage.getItem("cart"));
      Swal.fire({
        icon: "success",
        title: "Berhasil",
      });
    },

    removeCart(row_id) {
      Swal.fire({
        title: "Apakah Anda Yakin?",
        text: "Data Akan Dihapus",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
      }).then((result) => {
        if (result.isConfirmed) {
          let self = this;
          self.cart.splice(self.cart.indexOf(row_id), 1);
          sessionStorage.setItem("cart", JSON.stringify(self.cart));
        }
      });
    },

    destroyCart() {
      sessionStorage.removeItem("cart");
      self.cart = [];
    },

    randomKey() {
      return new Date().getTime() + Math.floor(Math.random() * 10000).toString();
    },

    jsontoString(jsonval) {
      try {
        jsonval
          .map(function (val) {
            return val.m_alat_tes_nama;
          })
          .join(",");
      } catch {
        return "";
      }
    },

    //Dropdown Unit
    onSearchUnit(search, loading) {
      if (search.length) {
        loading(true);
        this.searchUnit(loading, search, this);
      }
    },
    searchUnit: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/unit",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_unit = response.data.data.referensi;
          loading(false);
        }

        console.log(localStorage.access_token);
      });
    },
    onChangeUnit() {
      let self = this;
      self.form_data.m_unit_idm_unit = self.selected_unit.m_unit_idm_unit;
      self.form_data.j_txt_unit = self.selected_unit.ju_txt_unit;
    },

    //Pilih Lokasi
    onSearchLokasi(search, loading) {
      if (search.length) {
        loading(true);
        this.searchLokasi(loading, search, this);
      }
    },
    searchLokasi: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/search-lokasi",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_lokasi = response.data.data.referensi;
          loading(false);
        }
      });
    },
    checkLokasi() {
      let self = this;
      this.$nextTick(() => {
        console.log(self.checkedLokasi);
      });
    },
    onChangeLokasi() {
      let self = this;
      self.form_data.m_lokasi_idm_lokasi = self.selected_lokasi.ml_id;
      self.form_data.j_txt_lokasi = self.selected_lokasi.ml_nama;
    },
    // submitForm() {
    //   console.log(this.form_data);
    // },

    //Kegiatan
    onSearchKegiatan(search, loading) {
      if (search.length) {
        loading(true);
        this.searchKegiatan(loading, search, this);
      }
    },
    searchKegiatan: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/kegiatan",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_kegiatan = response.data.data.referensi;
          loading(false);
        }
      });
    },
    onChangeKegiatan() {
      let self = this;
      self.form_data.m_jadwal_kegiatan_idm_kegiatan = self.selected_kegiatan.id;
      self.form_data.j_txt_kegiatan = self.selected_kegiatan.kegiatan;
    },

    // Sub Kegiatan
    onSearchSubKegiatan(search, loading) {
      if (search.length) {
        loading(true);
        this.searchKegiatan(loading, search, this);
      }
    },
    searchSubKegiatan: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/sub-kegiatan",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_sub_kegiatan = response.data.data.referensi;
          loading(false);
        }
      });
    },
    onChangeSubKegiatan() {
      let self = this;
      self.form_data.m_id_subkegiatan = self.selected_sub_kegiatan.id_subkegiatan;
      self.form_data.m_nama_kegiatan = self.selected_sub_kegiatan.nama_subkegiatan;
    },

    //Jenis Asesmen
    onSearchJenisAsesmen(search, loading) {
      if (search.length) {
        loading(true);
        this.searchJenisAsesmen(loading, search, this);
      }
    },
    searchJenisAsesmen: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/jenis-asesmen",
        params: {
          keyword: search,
          id_kegiatan: 31,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_jenis_asesmen = response.data.data.referensi;
          loading(false);
        }
      });
    },
    onChangeJenisAsesmen() {
      let self = this;
      self.form_data.m_dirkom_idm_dirkom = self.seleceted_jenis_asesmen.id;
      self.form_data.j_txt_dirkom = self.seleceted_jenis_asesmen.nama_jenisasesmen;
      self.searchCheklist();
      self.showbatre = true;
    },

    //Batre Test
    onSearchBatreTest(search, loading) {
      if (search.length) {
        loading(true);
        this.searchBatreTest(loading, search, this);
      }
    },
    searchBatreTest: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/batre-test",
        params: {
          keyword: search,
          tipe: "Rekrutmen",
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_batre_test = response.data.data.referensi;

          loading(false);
        }
      });
    },
    onChangeBatreTest() {
      let self = this;
      self.form_data.m_batre_test_bt_id = self.seleceted_batre_test.bt_id;
      self.form_data.j_txt_batre_test = self.seleceted_batre_test.bt_nama;
      self.getAlatTes();
    },

    //alattes
    getAlatTes() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/batre-alat-test",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: {
          id_batretest: self.seleceted_batre_test.bt_id,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.option_alat_test = response_data.data.referensi;
            var data = [];
            self.option_alat_test.map((value) => {
              data.push(value.id_alattes_global);
            });
            let stringArray = data.map(String);
            let jsonString = JSON.stringify(stringArray); // Convert array of strings to JSON-formatted string
            let finalString = "[" + jsonString.slice(1, -1) + "]"; // Add square brackets manually
            self.form_data.id_alattes_global = finalString;
            self.form_data.j_json_alattes = self.option_alat_test.map(({ m_alat_tes_id, m_alat_tes_nama }) => ({ m_alat_tes_id, m_alat_tes_nama }));
            console.log(self.form_data.j_json_alattes);
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    //Tugas
    onSearchTugas(search, loading) {
      if (search.length) {
        loading(true);
        this.searchTugas(loading, search, this);
      }
    },
    searchTugas: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/tugas",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_tugas = response.data.data.referensi;
          loading(false);
        }
      });
    },

    //Asesor
    onSearchAsesor(search, loading) {
      if (search.length) {
        loading(true);
        this.searchAsesor(loading, search, this);
      }
    },
    searchAsesor: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/search-user",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_asesor = response.data.data.referensi;
          self.option_asesor.map(function (x) {
            return (x.item_data = x.u_nama + " (" + x.u_role + ")");
          });
          loading(false);
        }
      });
    },

    //PTL
    onSearchPTL(search, loading) {
      if (search.length) {
        loading(true);
        this.searchPTL(loading, search, this);
      }
    },
    searchPTL: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/search-user",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_ptl = response.data.data.referensi;
          self.option_ptl.map(function (x) {
            return (x.item_data = x.u_nama + " (" + x.u_role + ")");
          });
          loading(false);
        }
      });
    },

    //checklist
    onSearchCheklist(search, loading) {
      if (search.length) {
        loading(true);
        this.searchCheklist(loading, search, this);
      }
    },
    searchCheklist: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/checklist-asesmen",
        params: {
          keyword: search,
          id_jenis_asesmen: self.form_data.m_dirkom_idm_dirkom,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_checklist = response.data.data.referensi;
          self.form_data.j_json_checklist = self.option_checklist;
          loading(false);
        }
      });
    },
    validasiTrans() {
      if (this.grup_name == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Nama Grup Tidak Boleh Kosong",
        });
        return false;
      } else {
        this.submitForm();
      }
    },

    submitForm() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/rekrutmen/store",
        data: { name_grup: self.grup_name, param: JSON.stringify(self.cart) },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
          if (response.data.meta.code == 200) {
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Berhasil Menambahkan Jadwal",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            }).then((result) => {
              let response_data = response.data;
              let response_data_fix = response_data.data[0];
              self.$router.push({
                name: "detail-rekrutmen",
                params: {
                  id: this.encryptedUrl(response_data_fix.toString()),
                },
              });
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
                this.destroyCart();
              }
            });
          }
        })
        .catch((error) => {
          Swal.close();
          console.log(error);
        });
    },
    addFormUnit() {
      this.form_unit.push({
        m_unit_slug: "",
        m_unit_idm_unit: "",
        jadwal_idjadwal: null,
        ju_txt_unit: "",
        jumlah_diundang: "",
      });
    },
    clearFormUnit(index) {
      this.form_unit[index].m_unit_slug = "";
      this.form_unit[index].m_unit_idm_unit = "";
      this.form_unit[index].jadwal_idjadwal = null;
      this.form_unit[index].ju_txt_unit = "";
      this.form_unit[index].jumlah_diundang = "";
      this.selected_unit_peserta.splice(index, 1);
      this.selected_jumlah_peserta.splice(index, 1);
      this.form_unit.splice(index, 1);
    },

    validasi() {
      if (this.form_data.j_txt_unit && this.form_data.m_unit_idm_unit == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Unit Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_txt_lokasi == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Lokasi Jadwal Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_metode_jadwal == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Metode Jadwal Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_txt_ptl == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Ptl Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_txt_tgl == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Tanggal Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_tanggal_mulai == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Tanggal Mulai Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_tanggal_sampai == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Tanggal Sampai Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_jam_mulai == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Jam Mulai Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_jam_sampai == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Jam Sampai Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_mulai_penilaian == "" && this.form_data.j_akhir_penilaian == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Tanggal Penilaian Tidak Boleh Kosong",
        });
        return false;
      }
      // j_keterangan
      if (this.form_data.j_keterangan == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Catatan Tidak Boleh Kosong",
        });
        return false;
      }
      // j_txt_kegiatan
      if (this.form_data.j_txt_kegiatan == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Kegiatan Tidak Boleh Kosong",
        });
        return false;
      }
      // sub kegiatan
      if (this.form_data.m_id_subkegiatan == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Sub Kegiatan Tidak Boleh Kosong",
        });
        return false;
      }
      // j_txt_dirkom
      if (this.form_data.j_txt_dirkom == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "jenis assesmen Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_tipe == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "tipe Psikologi Tidak Boleh Kosong",
        });
        return false;
      }
      if (containsDisallowedCharacters(this.form_data.j_keterangan)) {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Terdapat karakter yang tidak diperbolehkan!",
        });
        return false; // Exit the function if validation fails
      }
      if (this.form_data.j_txt_batre_test == "" || this.form_data.m_batre_test_bt_id == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Batre Test Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.j_json_alattes == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Alat Tes Tidak Boleh Kosong",
        });
        return false;
      } else {
        this.addCart();
      }
    },

    onChangeTipe() {
      if (this.form_data.j_tipe == "Kompetensi & Potensi" || this.form_data.j_tipe == "Potensi") {
        this.showbatre = true;
      } else this.showbatre = false;
      return this.showbatre;
    },

    onChangeUnitPeserta(index) {
      let self = this;
      self.form_unit[index].ju_txt_unit = self.selected_unit_peserta[index].ju_txt_unit;
      self.form_unit[index].m_unit_idm_unit = self.selected_unit_peserta[index].m_unit_idm_unit;
      self.form_unit[index].m_unit_slug = self.selected_unit_peserta[index].m_unit_slug;

      self.form_data.j_json_unitclient = self.form_unit;
    },

    onChangeTugas(index) {
      let self = this;
      self.form_tugas[index].tugas_txt = self.selected_tugas[index].tugas_txt;
      self.form_tugas[index].tugas_id = self.selected_tugas[index].tugas_id;

      self.form_data.j_json_petugas = self.form_tugas;
    },

    onChangeAsesor(index) {
      let self = this;
      self.form_tugas[index].petugas_txt = self.selected_petugas_aesor[index];

      self.form_data.j_json_petugas = self.form_tugas;
    },

    onChangeTglSampai(date) {
      const currentDate = new Date(date);
      currentDate.setDate(currentDate.getDate() + 4);
      this.form_data.j_akhir_penilaian = currentDate.toISOString().slice(0, 10);

      const currentDate2 = new Date(date);
      currentDate2.setDate(currentDate2.getDate() + 1);
      this.form_data.j_mulai_penilaian = currentDate2.toISOString().slice(0, 10);
    },

    addFormTugas() {
      this.form_tugas.push({
        tugas_id: "",
        tugas_txt: "",
        jadwal_idjadwal: null,
        petugas_id: "",
        petugas_txt: "",
      });
    },
    clearFormTugas(index) {
      this.form_tugas[index].tugas_id = "";
      this.form_tugas[index].tugas_txt = "";
      this.form_tugas[index].jadwal_idjadwal = null;
      this.form_tugas[index].petugas_id = "";
      this.form_tugas[index].petugas_txt = "";
      this.selected_tugas.splice(index, 1);
      this.form_tugas.splice(index, 1);
    },

    onChangeChecklist(index) {
      let self = this;
      self.form_checklist[index].id_ca = self.selected_checklist[index].id_ca;
      self.form_checklist[index].id_checklist = self.selected_checklist[index].id_checklist;
      self.form_checklist[index].nama_checklist = self.selected_checklist[index].nama_checklist;

      self.form_data.j_json_checklist = self.form_checklist;
    },
    addFormChecklist() {
      this.form_checklist.push({
        id_ca: "",
        id_checklist: "",
        nama_checklist: "",
        jadwal_idjadwal: null,
      });
    },
    clearFormChecklist(index) {
      this.form_checklist[index].id_ca = "";
      this.form_checklist[index].id_checklist = "";
      this.form_checklist[index].nama_checklist = "";
      this.form_checklist[index].jadwal_idjadwal = null;
      this.selected_checklist.splice(index, 1);
      this.form_checklist.splice(index, 1);
    },

    onChangePTL() {
      let self = this;
      self.form_data.m_user_id_ptl = self.selected_ptl.iduser;
      self.form_data.m_user_nip_ptl = self.selected_ptl.u_nip;
      self.form_data.j_txt_ptl = self.selected_ptl.u_nama;
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6" style="border-right: 2px solid #ddd">
                  <b-form-group class="mb-3" label="Unit Pelaksana" label-for="form-unit-pelaksana">
                    <v-select :options="option_unit" label="m_unit_slug" v-model="selected_unit" placeholder="-Pilih Unit-" @search="onSearchUnit" @update:modelValue="onChangeUnit()"></v-select>
                  </b-form-group>

                  <div class="row" v-for="(form, k) in form_unit" :key="k">
                    <div class="col-md-5">
                      <b-form-group class="mb-3" label="Unit Client" label-for="ptl">
                        <v-select
                          :options="option_unit"
                          label="m_unit_slug"
                          v-model="selected_unit_peserta[k]"
                          placeholder="-Pilih Unit-"
                          @search="onSearchUnit"
                          @update:modelValue="onChangeUnitPeserta(k)"
                        ></v-select>
                      </b-form-group>
                    </div>
                    <div class="col-md-5">
                      <b-form-group class="mb-3" label="Peserta Diundang" label-for="pesertaDiundang">
                        <b-form-input id="pesertaDiundang" placeholder="Masukkan jumlah peserta diundang" type="text" v-model="form_unit[k].jumlah_diundang"></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="d-grid gap-2 col-md-2 my-auto" v-if="k == 0">
                      <button class="btn btn-success btn-block mt-2" type="button" @click="addFormUnit()">
                        <span><i class="fa fa-plus"></i> </span>
                      </button>
                    </div>
                    <div class="d-grid gap-2 col-md-2 my-auto" v-if="k > 0">
                      <button class="btn btn-danger btn-block mt-2" @click="clearFormUnit(k)" type="button">
                        <span><i class="fa fa-minus"></i> </span>
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-2">
                      <b-form-group class="mb-3" label="Lokasi" label-for="lokasi">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" v-model="checkedLokasi" value="true" id="autoSizingCheck" @change="checkLokasi($event)" />
                          <label class="form-check-label" for="autoSizingCheck"> Isi Text </label>
                        </div>
                      </b-form-group>
                    </div>
                    <div class="col-md-10">
                      <b-form-group class="mb-3" v-if="!checkedLokasi" label="Pilih Lokasi" label-for="lokasi">
                        <v-select
                          :options="option_lokasi"
                          label="ml_nama"
                          v-model="selected_lokasi"
                          placeholder="-Pilih Lokasi-"
                          @search="onSearchLokasi"
                          @update:modelValue="onChangeLokasi()"
                        ></v-select>
                      </b-form-group>
                      <b-form-group class="mb-3" v-else label="Lokasi" label-for="lokasiText">
                        <b-form-input id="lokasiText" placeholder="Masukkan Nama Lokasi" type="text" v-model="form_data.j_txt_lokasi"></b-form-input>
                      </b-form-group>
                    </div>
                  </div>

                  <b-form-group class="mb-3" label="Metode Jadwal" label-for="form-metode">
                    <v-select :options="['Online', 'Offline']" v-model="form_data.j_metode_jadwal" placeholder="-Pilih Metode Jadwal-"></v-select>
                  </b-form-group>

                  <b-form-group class="mb-3" label="PTL" label-for="ptl">
                    <v-select :options="option_ptl" label="label" v-model="selected_ptl" placeholder="-Pilih PTL-" @search="onSearchPTL" @update:modelValue="onChangePTL()"></v-select>
                  </b-form-group>

                  <div class="row" v-for="(form, k) in form_tugas" :key="k">
                    <div class="col-md-5">
                      <b-form-group class="mb-3" label="Petugas" label-for="petugas">
                        <v-select
                          :options="option_asesor"
                          label="item_data"
                          v-model="selected_petugas_aesor[k]"
                          placeholder="-Pilih Petugas-"
                          @search="onSearchAsesor"
                          @update:modelValue="onChangeAsesor(k)"
                        ></v-select>
                      </b-form-group>
                    </div>
                    <div class="col-md-5">
                      <b-form-group class="mb-3" label="Tugas" label-for="tugas">
                        <v-select
                          :options="option_tugas"
                          label="tugas_txt"
                          v-model="selected_tugas[k]"
                          placeholder="-Pilih Tugas-"
                          @search="onSearchTugas"
                          @update:modelValue="onChangeTugas(k)"
                        ></v-select>
                      </b-form-group>
                    </div>

                    <div class="d-grid gap-2 col-md-2 my-auto" v-if="k == 0">
                      <button class="btn btn-success btn-block mt-2" type="button" @click="addFormTugas()">
                        <span><i class="fa fa-plus"></i> </span>
                      </button>
                    </div>
                    <div class="d-grid gap-2 col-md-2 my-auto" v-if="k > 0">
                      <button class="btn btn-danger btn-block mt-2" @click="clearFormTugas(k)" type="button">
                        <span><i class="fa fa-minus"></i> </span>
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group class="mb-3" label="Tanggal Mulai" label-for="tgl-mulai">
                        <b-form-input id="tgl-mulai" type="date" v-model="form_data.j_tanggal_mulai"></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group class="mb-3" label="Tanggal Selesai" label-for="tgl-selesai">
                        <b-form-input
                          id="tgl-selesai"
                          placeholder="Tanggal Selesai"
                          type="date"
                          v-model="form_data.j_tanggal_sampai"
                          @update:modelValue="onChangeTglSampai(form_data.j_tanggal_sampai)"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group class="mb-3" label="Jam Mulai" label-for="form-jam-mulai">
                        <b-form-input id="form-jam-mulai" type="time" v-model="form_data.j_jam_mulai"></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group class="mb-3" label="Jam Selesai" label-for="form-jam-selesai">
                        <b-form-input id="form-jam-selesai" type="time" v-model="form_data.j_jam_sampai"></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group class="mb-3" label="Mulai Penilaian" label-for="form-jam-mulai">
                        <b-form-input id="form-jam-mulai" type="date" v-model="form_data.j_mulai_penilaian"></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group class="mb-3" label="Akhir Penilaian" label-for="form-jam-selesai">
                        <b-form-input id="form-jam-selesai" type="date" v-model="form_data.j_akhir_penilaian"></b-form-input>
                      </b-form-group>
                    </div>
                  </div>

                  <b-form-group class="mb-3" label="Catatan" label-for="Catatan">
                    <b-form-textarea id="Catatan" type="textarea" v-model="form_data.j_keterangan"></b-form-textarea>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group class="mb-3" label="Kegiatan" label-for="form-unit-pelaksana">
                    <b-form-input id="form-kegiatan" class="bg-secondary text-white" type="text" readonly v-model="form_data.j_txt_kegiatan"></b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Sub Kegiatan" label-for="form-sub-jenjang">
                    <v-select
                      :options="option_sub_kegiatan"
                      label="nama_subkegiatan"
                      v-model="selected_sub_kegiatan"
                      placeholder="-Pilih Sub Kegiatan-"
                      @search="onSearchSubKegiatan"
                      @update:modelValue="onChangeSubKegiatan()"
                    ></v-select>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Jenjang" label-for="form-jenjang">
                    <v-select
                      :options="option_jenis_asesmen"
                      label="nama_jenisasesmen"
                      v-model="seleceted_jenis_asesmen"
                      placeholder="-Pilih Jenjang-"
                      @search="onSearchJenisAsesmen"
                      @update:modelValue="onChangeJenisAsesmen()"
                    ></v-select>
                  </b-form-group>

                  <b-form-group class="mb-3" label="Tipe Rekrutmen" label-for="form-tipe">
                    <b-form-input id="form-metode" class="bg-secondary text-white" type="text" readonly v-model="form_data.j_tipe"></b-form-input>
                  </b-form-group>

                  <div class="row" v-if="showbatre == true">
                    <div class="col-md-12">
                      <b-form-group class="mb-3" label="Batre Test" label-for="form-batre-test">
                        <v-select
                          :options="option_batre_test"
                          label="bt_nama"
                          v-model="seleceted_batre_test"
                          placeholder="-Pilih Batre Test-"
                          @search="onSearchBatreTest"
                          @update:modelValue="onChangeBatreTest()"
                        ></v-select>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="row row-cols-12" v-if="seleceted_batre_test != ''">
                    <b-form-group class="mb-3" label="Alat Test" label-for="form-alat-test">
                      <b-form-input
                        id="form-alat-test"
                        class="bg-secondary text-white"
                        type="text"
                        readonly
                        v-model="alattes.m_alat_tes_nama"
                        v-for="alattes in option_alat_test"
                        :key="alattes.m_alat_tes_id"
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="row-cols-12" v-if="seleceted_jenis_asesmen != ''">
                    <b-form-group class="mb-3" label="Checklist" label-for="checklist"></b-form-group>
                    <b-form-input
                      id="form-checklist"
                      class="bg-secondary text-white"
                      type="text"
                      readonly
                      v-model="checklist.nama_checklist"
                      v-for="checklist in option_checklist"
                      :key="checklist.id_ca"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mg-5">
            <div class="float-end" style="text-align: right">
              <button class="btn btn-primary btn-sm mt-2 m-4" type="button" @click="validasi()">
                <span><i class="fa fa-arrow-right"></i> Tambahkan </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="card border-primary">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <b-form-group class="mb-3" label="Metode" label-for="form-metode">
                  <b-form-input id="form-metode" class="bg-secondary text-white" type="text" readonly v-model="form_data.j_metode"></b-form-input>
                </b-form-group>

                <b-form-group class="mb-3" label="Nama Grup" label-for="form-nama-grup">
                  <b-form-input id="form-nama-grup" class="form-control" type="text" v-model="grup_name"></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-8">
        <div class="card border-primary">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered mb-0 mt-2">
                <thead class="bg-secondary text-white" style="padding: 10px">
                  <th style="padding: 5px">No</th>
                  <th style="padding: 5px">Data Jadwal</th>
                  <th style="padding: 5px">Petugas & Tugas</th>
                  <th style="padding: 5px">Aksi</th>
                </thead>
                <tr v-for="(item, k) in cart" :key="item.row_id">
                  <td>{{ k + 1 }}</td>
                  <td>
                    - Unit Pelaksana : {{ item.param.j_txt_unit }} <br />
                    - Unit Client :
                    <span class="p-0" v-if="item.param.j_json_unitclient != ''">
                      {{
                        item.param.j_json_unitclient
                          .map(function (val) {
                            return val.ju_txt_unit + "(" + val.jumlah_diundang + ")";
                          })
                          .join(",")
                      }}
                    </span>
                    <br />
                    - Kegiatan : {{ item.param.j_txt_kegiatan }} <br />
                    - Jenis Asesmen : {{ item.param.j_txt_dirkom }} <br />
                    - Tipe Rekrutmen : {{ item.param.j_tipe }} <br />
                    <span class="p-0" v-if="item.param.j_tipe == 'Potensi' || item.param.j_tipe == 'Kompetensi & Potensi'">
                      - Batre Test : {{ item.param.j_txt_batre_test }} <br />
                      - Alat Tes :
                      {{
                        item.param.j_json_alattes
                          .map(function (val) {
                            return val.m_alat_tes_nama;
                          })
                          .join(",")
                      }}
                      <br />
                    </span>
                    - Lokasi : {{ item.param.j_txt_lokasi }} <br />
                    - Metode Jadwal : {{ item.param.j_metode_jadwal }} <br />
                    - PTL : {{ item.param.j_txt_ptl }} <br />
                    - Tanggal Mulai : {{ item.param.j_tanggal_mulai }} <br />
                    - Tanggal Selesai : {{ item.param.j_tanggal_sampai }} <br />
                    - Jam Mulai : {{ item.param.j_jam_mulai }} <br />
                    - Jam Selesai : {{ item.param.j_jam_sampai }} <br />
                    - Mulai Penilaian : {{ item.param.j_mulai_penilaian }}
                    <br />
                    - Akhir Penilaian : {{ item.param.j_akhir_penilaian }}
                    <br />
                    - Catatan : {{ item.param.j_keterangan }} <br />
                    - Checklist :
                    <span class="p-0" v-if="item.param.j_json_checklist != ''">
                      {{
                        item.param.j_json_checklist
                          .map(function (val) {
                            return val.nama_checklist;
                          })
                          .join(",")
                      }}
                    </span>
                  </td>
                  <td>
                    <span v-for="(petugas, i) in item.param.j_json_petugas" :key="i"> - {{ petugas.petugas_txt.u_nama }} ({{ petugas.tugas_txt }}) <br /> </span>
                  </td>
                  <td class="bg-white">
                    <button class="btn btn-danger btn-sm" type="button" @click="removeCart(item.row_id)" style="background-color: red">
                      <span><i class="fa fa-trash"></i></span>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row col-12">
      <button class="btn btn-primary mt-2 m-4" type="button" @click="validasiTrans()">
        <span><i class="fa fa-save"></i> Simpan </span>
      </button>
    </div>
  </Layout>
</template>
