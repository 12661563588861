<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  props: ["idjadwal"],
  data() {
    return {
      table_data: [],
      default_table_data: [],
      last_update: "",
      saran: "",
      master_user_id: "",
      rating: 0,
      isPerfectRating: false,
      table_penilian_kerja: [
        {
          star: 1,
          label: "Mengecewakan",
        },
        {
          star: 2,
          label: "Kurang Memuaskan",
        },
        {
          star: 3,
          label: "Cukup Memuaskan",
        },
        {
          star: 4,
          label: "Memuaskan",
        },
        {
          star: 5,
          label: "Sangat Memuaskan",
        },
      ],
    };
  },
  mounted() {
    let self = this;
    self.getData();
    self.getLastUpdate();
    self.getSaran();
    var user_id = JSON.parse(localStorage.user);
    this.master_user_id = user_id.master_user_id;
  },
  methods: {
    setRating(star) {
      if (star == 5) {
        this.isPerfectRating = true;
        this.table_data.forEach((item) => {
          let proses = item;
          proses.pertanyaan.forEach((pertanyaan) => {
            pertanyaan.is_check = 3;
          });
        });
        this.rating = star; // Set the rating when a star is clicked
      } else {
        this.isPerfectRating = false;
        if (this.rating == 5) {
          this.table_data = JSON.parse(JSON.stringify(this.default_table_data));
        }
        this.rating = star;
      }
    },
    getData() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "qc/get-list-qc",
        params: {
          idjadwal: this.$props.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.table_data = response_data.data;
            self.default_table_data = JSON.parse(
              JSON.stringify(response_data.data)
            );
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    getLastUpdate() {
      // let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "qc/get-last-update",
        params: {
          idjadwal: this.$props.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;

          if (response_data.meta.code == 200) {
            this.last_update = response_data.data.updated_at;
            console.log(this.last_update);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getSaran() {
      // let self = this;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "qc/saran/" +
          this.$props.idjadwal,
        params: {
          idjadwal: this.$props.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;

          if (response_data.meta.code == 200) {
            this.saran = response_data.data.qcs_saran;
            this.rating = response_data.data.qcs_rating;
            if (this.rating == 5) {
              this.isPerfectRating = true;
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    storeSaran() {
      // let self = this;
      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "qc/saran/" +
          this.$props.idjadwal,
        params: {
          saran: this.saran,
          rating: this.rating,
          master_user_id: this.master_user_id,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then(() => {})
        .catch((errors) => {
          console.log(errors);
        });
    },

    toggleCheckbox(row_data) {
      row_data.is_check = row_data.is_check === 1 ? 0 : 1;
    },
    submit() {
      if (this.rating == 0) {
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Silahkan isi penilaian kerja terlebih dahulu",
        });
      }
      this.storeSaran();
      var dataToSubmit = [];
      this.table_data.forEach((item) => {
        let proses = item;
        proses.pertanyaan.forEach((pertanyaan) => {
          pertanyaan.is_check =
            pertanyaan.is_check === 0 ? "3" : pertanyaan.is_check;
          dataToSubmit.push({
            id_petugas_ap: pertanyaan.id_petugas_ap,
            is_check: pertanyaan.is_check,
            keterangan: pertanyaan.keterangan || "",
            master_user_id: this.master_user_id,
          });
        });
      });
      // const invalidRows = this.table_data.filter((row_data) => {
      //   return (
      //     row_data.is_check != "4" &&
      //     (!row_data.keterangan || row_data.keterangan.trim() === "")
      //   );
      // });

      // // If there are invalid rows, show an error message and prevent submission

      // // Process each item in dataToSubmit
      // const dataToSubmit = this.table_data.map(
      //   ({ id_petugas_ap, is_check, keterangan }) => ({
      //     id_petugas_ap,
      //     is_check,
      //     keterangan: keterangan || "", // Set keterangan to empty string if it's null
      //     master_user_id: this.master_user_id,
      //   })
      // );

      // // Update records
      // dataToSubmit.forEach((item) => {
      //   const { id_petugas_ap, is_check, keterangan, master_user_id } = item;

      //   // Perform your axios request to update the record here
      //   // For demonstration, I'm logging the update operation
      //   console.log(
      //     `Updating record with id_petugas_ap: ${id_petugas_ap}, is_check: ${is_check}, keterangan: ${keterangan}, master_user_id: ${master_user_id}`
      //   );
      // });

      // console.log(dataToSubmit);
      // if (invalidRows.length > 0) {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Oops...",
      //     text: "Isi Keterangan untuk pilihan selain Sesuai",
      //   });
      //   return;
      // }

      axios
        .post(
          process.env.VUE_APP_BACKEND_URL_VERSION + "qc/update-is-check-ap",
          dataToSubmit,
          {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          }
        )
        .then((response) => {
          if (response.data.meta.code === 200) {
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: response.data.meta.message,
            });
            this.getData();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response.data.meta.message,
            });
          }
        })
        .catch((error) => {
          console.error("Error submitting data:", error);
        });
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
};
</script>
<template>
  <div class="row">
    <span
      >Terakhir di update :
      <b v-if="last_update != ''">{{ formatDate(last_update) }}</b>
      <b v-else>-</b></span
    >
  </div>
  <div class="row">
    <div class="col-12">
      <h5 class="d-flex align-items-center">
        Beri Penilaian Kinerja
        <div class="rating ms-2">
          <span
            v-for="n in 5"
            :key="n"
            class="rating-star"
            :class="{ 'rating-star-checked': n <= rating }"
            @click="setRating(n)"
          >
            <i class="fas fa-star"></i>
          </span>
        </div>
      </h5>
      <h6>
        <b v-if="rating == 1">Mengecewakan</b>
        <b v-if="rating == 2">Kurang Memuaskan</b>
        <b v-if="rating == 3">Cukup Memuaskan</b>
        <b v-if="rating == 4">Memuaskan</b>
        <b v-if="rating == 5">Sangat Memuaskan</b>
      </h6>
    </div>
  </div>
  <div class="row mt-2" v-if="rating && !isPerfectRating">
    <h6>Apa yang masih perlu ditingkatkan?</h6>
    <div class="accordion" id="accordionExample">
      <div
        class="accordion-item"
        v-for="(row_data, index) in table_data"
        :key="row_data.id_petugas_ap"
      >
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#collapse' + index"
            aria-expanded="true"
            :aria-controls="'collapse' + index"
          >
            {{ row_data?.nama_proses }}
          </button>
        </h2>
        <div
          :id="'collapse' + index"
          class="accordion-collapse collapse"
          :aria-labelledby="'heading' + index"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <table class="table table-bordered mb-0 mt-2">
              <thead class="bg-dark text-white" style="padding: 10px">
                <th style="padding: 5px">No</th>
                <th style="padding: 5px">Proses</th>
                <th style="padding: 5px">Pertanyaan</th>
                <th style="padding: 5px; width: 20%">Aksi</th>
                <th style="padding: 5px; width: 20%">Keterangan</th>
              </thead>
              <tbody>
                <tr
                  v-for="(pertanyaan, index) in row_data.pertanyaan"
                  :key="pertanyaan.id_petugas_ap"
                >
                  <td class="text-center">{{ index + 1 }}</td>
                  <td>{{ pertanyaan.nama_proses }}</td>
                  <td>{{ pertanyaan.pertanyaan }}</td>
                  <td>
                    <div>
                      <input
                        type="radio"
                        :id="
                          'option1-' + pertanyaan.id_petugas_ap + '-' + index
                        "
                        :name="
                          'option-' + pertanyaan.id_petugas_ap + '-' + index
                        "
                        value="1"
                        v-model="pertanyaan.is_check"
                        :checked="pertanyaan.is_check == '1'"
                        class="me-2"
                      />
                      <label
                        :for="
                          'option1-' + pertanyaan.id_petugas_ap + '-' + index
                        "
                        >Needs Guidance</label
                      >
                    </div>
                    <div>
                      <input
                        type="radio"
                        :id="
                          'option2-' + pertanyaan.id_petugas_ap + '-' + index
                        "
                        :name="
                          'option-' + pertanyaan.id_petugas_ap + '-' + index
                        "
                        value="2"
                        v-model="pertanyaan.is_check"
                        :checked="pertanyaan.is_check == '2'"
                        class="me-2"
                      />
                      <label :for="'option2-' + index">Needs Improvement</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        :id="
                          'option3-' + pertanyaan.id_petugas_ap + '-' + index
                        "
                        :name="
                          'option-' + pertanyaan.id_petugas_ap + '-' + index
                        "
                        value="3"
                        v-model="pertanyaan.is_check"
                        :checked="pertanyaan.is_check == '3'"
                        class="me-2"
                      />
                      <label :for="'option3-' + index">Good</label>
                    </div>
                  </td>
                  <td>
                    <textarea v-model="pertanyaan.keterangan"></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2" v-if="rating > 0">
    <div class="col-12"><b>Kesimpulan dan Saran :</b></div>
    <div class="col-12">
      <b-form-group class="mb-3">
        <b-form-textarea id="keterangan" type="textarea" v-model="saran">{{
          saran
        }}</b-form-textarea>
      </b-form-group>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-md-8 border border-light p-2 ms-3">
      <div class="row">
        <div class="col-4 ">
          <h6><b>Penilaian Kerja</b></h6>
          <table style="padding: 0 !important; width: 100%">
            <tr v-for="item in table_penilian_kerja" :key="item">
              <td>
                <i
                  class="fas fa-star"
                  style="color: #ffca28; font-size: small"
                  v-for="star in item.star"
                  :key="star"
                ></i>
              </td>
              <td>: {{ item.label }}</td>
            </tr>
          </table>
        </div>
        <div class="col-8  p-2">
          <h6><b>Skala Penilaian</b></h6>
          <table style="padding: 0 !important; width: 100%">
            <tr>
              <td style="vertical-align: top">Good</td>
              <td style="vertical-align: top">:</td>
              <td style="vertical-align: top">
                Menunjukkan kinerja yang konsisten dan memenuhi semua standar
                yang diharapkan. Perilaku/kinerja yang ditampilkan akurat, tepat
                waktu, dan sejalan dengan pedoman yang telah ditetapkan
              </td>
            </tr>
            <tr>
              <td style="vertical-align: top">Needs Improvement</td>
              <td style="vertical-align: top">:</td>
              <td style="vertical-align: top">
                Menunjukkan kinerja yang mendekati standar, namun terdapat
                beberapa area yang perlu ditingkatkan. Perilaku/kinerja yang
                ditampilkan kurang konsisten atau kurang mendalam, dan
                memerlukan perhatian lebih lanjut
              </td>
            </tr>
            <tr >
              <td style="vertical-align: top">Needs Guidence</td>
              <td style="vertical-align: top">:</td>
              <td style="vertical-align: top">
                Tidak memenuhi standar yang diharapkan. Perilaku/kinerja yang
                ditampilkan tidak akurat, tidak tepat waktu, atau tidak sesuai
                dengan pedoman. Memerlukan pembinaan atau pelatihan lebih
                lanjut.
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="col-2 text-end">
      <button class="btn btn-primary" @click="submit">
        <span><i class="fa fa-save"></i> Simpan</span>
      </button>
    </div>
  </div>
</template>

<style scoped>
.rating {
  font-size: 1.5em;
}

.rating-star {
  cursor: pointer;
  color: #ddd;
}

.rating-star-checked {
  color: #ffca28; /* Color for the selected stars */
}
</style>
